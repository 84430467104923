.accomplishments-section {
  h1 > span{
    margin-top: -55px;  /* Size of fixed header */
    padding-bottom:55px; 
    display: block; 
  }
  
  .card {
    background: #fff;
    border-top: 2px solid #248aaa;
    height: 100%;
  }
  .card .card-header {
    background: none;
    border: none;
  }
  
  .card .card-header .sub-title {
    color: #8392a5;
    margin-top: 0.4rem;
  }
  
  .card .sub-title :nth-child(2) {
    float: none !important;
  }
  
  .card .card-body {
    padding: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .card .card-footer {
    background: #fff;
    border: none;
    padding: 0;
    padding-left: 0.7rem;
    padding-bottom: 0.3rem;
  }
  
  /* ============= Device specific fixes ======= */
  
  /* Large screens such as TV */
  @media only screen and (min-width: 1824px) {
  }
  
  /* Extra large devices (large desktops, 1200px and up) */
  
  @media (max-width: 1400px) {
  }
  
  @media (max-width: 1200px) {
  }
  
  /* IPad Pro */
  @media (max-width: 1024px) {
    width: 100%;
    padding: 0;
    padding-left: 0.2rem;
    padding-right: 0.2rem;

    .container {
      max-width: 100%;
    }
  }
  
  /*  Large devices (desktops, 992px and up) */
  
  @media (max-width: 992px) {
  }
  
  /* Medium devices (tablets, 768px and up) */
  
  @media only screen and (max-width: 768px) {
  
  }
  
  /* Small devices (landscape phones, 576px and up) */
  
  @media only screen and (max-width: 576px) {
    flex: 100%;
    max-width: 100%;
    margin-top: 2rem;
  }
  
  /* iPhoneX, iPhone 6,7,8 */
  @media only screen and (max-width: 375px) {
  }
  
  /* Galaxy S5, Moto G4 */
  @media only screen and (max-width: 360px) {
  }
  
  /* iPhone 5 or before */
  @media only screen and (max-width: 320px) {
  }  
}