.achievements-section {
  .container {
    padding-top: 0.5rem;
  }
  
  h1 > span{
    margin-top: -55px;  /* Size of fixed header */
    padding-bottom:55px; 
    display: block; 
  }
  
  #gallery .achievement-entry {
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 7px;
    margin-right: 7px;
    z-index: 1;
    background-color: #e5e9f2;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-out;
  }
  
  #gallery .achievement-entry:hover {
    cursor: pointer;
    z-index: 1;
    transform: scale(1.1);
    transition: all 0.3s ease-out;
    z-index: 20000;
  }
  
  #gallery .achievement-details {
    cursor: pointer;
    z-index: 1;
    opacity: 1 !important;
    transition: none !important;
    transform: none !important;
  }
  
  #gallery .img-type-1 {
    height: 300px;
  }
  #gallery .img-type-2 {
    height: 146px;
    margin-bottom: 8px;
  }
  
  #gallery .svg-inline--fa {
    color: #8392a5;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px;
    font-size: 0rem;
    opacity: 0;
  }
  
  #gallery .achievement-entry:hover .svg-inline--fa {
    opacity: 1;
    font-size: 1rem;
    transition: all 0.3s ease-out;
  }
  
  #gallery .img-type-1 .svg-inline--fa {
    margin-top: 135px;
  }
  #gallery .img-type-2 .svg-inline--fa {
    margin-top: 50px;
  }
  
  #gallery .achievement-details.img-type-1 .svg-inline--fa,
  .achievement-details.img-type-2 .svg-inline--fa {
    margin-top: 0px !important;
    transition: none !important;
    float: right;
  }
  
  #gallery .achievement-entry .title {
    color: #e5e9f2;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    padding: 5px;
    position: absolute;
    bottom: 0px;
    width: 100%;
    margin-bottom: 0px;
    bottom: -5px;
  }
  
  #gallery .achievement-entry:hover .title {
    opacity: 1;
    bottom: 0px;
    transition: bottom 0.3s ease-out;
  }
  
  #gallery .caption {
    background-color: rgba(0, 0, 0, 0.7);
    bottom: 1rem;
    left: 1rem;
    color: #e5e9f2;
    padding: 15px;
    position: absolute;
    transition: all 0.3s ease-out;
  }
  
  #gallery .caption h4 {
    color: #e5e9f2;
  }
  
  #gallery .caption p {
    font-size: 16px;
    font-weight: 300;
    color: #e5e9f2;
  }
  
  .hidden {
    display: none !important;
  }
  
  #gallery .achievement-details {
    height: 75vh !important;
  }
  
  /* ============= Device specific fixes ======= */
  
  /* Large screens such as TV */
  @media only screen and (min-width: 1824px) {
  }
  
  /* Extra large devices (large desktops, 1200px and up) */
  
  @media (max-width: 1400px) {
  }
  
  @media (max-width: 1200px) {
  }
  
  /* IPad Pro */
  @media (max-width: 1024px) {
  }
  
  /*  Large devices (desktops, 992px and up) */
  
  @media (max-width: 992px) {
  }
  
  /* Medium devices (tablets, 768px and up) */
  
  @media only screen and (max-width: 768px) {
    .container {
      max-width: 100%;
    }
    .col-md-6 {
      flex: 50%;
      width: 50%;
    }
  }
  
  /* Small devices (landscape phones, 576px and up) */
  
  @media only screen and (max-width: 576px) {
    #gallery .achievement-entry:hover {
      transform: scale(1.05);
    }
  }
  
  /* iPhoneX, iPhone 6,7,8 */
  @media only screen and (max-width: 375px) {
  }
  
  /* Galaxy S5, Moto G4 */
  @media only screen and (max-width: 360px) {
  }
  
  /* iPhone 5 or before */
  @media only screen and (max-width: 320px) {
  }  
}