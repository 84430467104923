body.type-notes {
  .wrapper {
    display: flex;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .content-section {
    flex: 80%;
    max-width: 80%;
    order: 2;
    /* background-color: lightseagreen; */
    padding: 0;
    position: relative;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  
  .content {
    padding: 0;
    position: relative;
    padding-top: 2rem;
    min-height: 130vh;
  }
  
  .note-card-holder{
    padding-top: 2rem;
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(auto-fill, minmax(30rem,1fr));
    grid-auto-rows: 20px;
  }
  
  /* .note-collection {
    display: flex;
    flex-wrap: wrap;
  } */
  
  .note-card {
    align-self: flex-start;
  }
  
  .note-card .card{
    margin: 0.5rem;
  }
  
  .note-title{
    padding-left: 1rem;
    color: #248aaa;
  }
  
  .note-title span{
    background: #e5e9f2;
    padding-right: 5px;
  }
  
  .note-title:before{
    content:'';
    display:inline-block;
    width:98%;
    height:100%;
    margin-bottom:-26px;
    border-bottom: 1px solid #248aaa;
  }
  
  .note-card .card-body{
    padding: 1rem;
  }
  
  .note-card pre {
    margin: 0;
    border-radius: 3px;
  }
  
  /* .small-note{
    max-width: 15rem;
  }
  
  .medium-note{
    max-width: 25rem;
  }
  
  .large-note{
    max-width: 40rem;
  }
  
  .huge-note{} */
  
  .note-badge{
    font-size: 10pt;
  }
  
  
  /* ============= Device specific fixes ======= */
  
  /* Large screens such as TV */
  @media only screen and (min-width: 1824px) {
    .content-section {
      padding-left: 1rem;
      padding-right: 1rem;
      flex: 85%;
      max-width: 85%;
    }
  }
  
  
  /* Extra large devices (large desktops, 1200px and up) */
  
  @media (max-width: 1400px) {
    .note-card-holder {
      margin-left: 0px;
    }
  }
  
  @media (max-width: 1200px) {
  
  }
  
  /* IPad Pro */
  @media (max-width: 1024px) {
    .wrapper {
      padding-left: 0px;
      padding-right: 0px;
    }
    .content-section {
      padding: 0;
      max-width: 100%;
      order: 2;
      padding-bottom: 0.5rem;
      transition: all ease-out 0.3s;
    }
  
    .content-section.hide {
      max-width: 60%;
      transition: all ease-out 0.3s;
    }
  
    .content {
      overflow: hidden;
    }
    .container {
      max-width: 100%;
    }
    .navbar-toggler {
      display: block;
    }
  
    #toc-toggler {
      visibility: hidden;
    }
  
    .navbar-collapse.lang-selector {
      display: block !important;
      position: absolute;
      right: 0;
      top: 0.5rem;
    }
  
    .note-card-holder {
      margin: 0;
      margin-top: 1.5rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      position: relative;
      transition: all ease-out 0.3s;
    }
  
    .content-section.hide .note-card-holder{
      grid-template-columns: repeat(auto-fill, minmax(30rem,1fr));
      transition: all ease-out 0.3s;
    }
  }
  
  /*  Large devices (desktops, 992px and up) */
  
  @media (max-width: 992px) {
    .note-card-holder .note-card {
      max-width: 100%;
      min-width: 50%;
      transition: all ease-out 0.3s;
    }
  
    .content-section.hide .note-card-holder .note-card {
      max-width: 100%;
      min-width: 100%;
      transition: all ease-out 0.3s;
    }
  }
  
  /* Medium devices (tablets, 768px and up) */
  
  @media only screen and (max-width: 768px) {
  
  }
  
  /* Small devices (landscape phones, 576px and up) */
  
  @media only screen and (max-width: 576px) {
    .wrapper {
      padding-left: 0px;
      padding-right: 0px;
      flex-flow: column;
      overflow: hidden;
    }
    .content-section, .content-section.hide {
      flex: 100%;
      max-width: 100%;
      padding-left: 0;
      width: 100%;
      transition: all ease-out 0.3s;
    }
  
    .content {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      transition: all ease-out 0.3s;
    }
  
    .content-section.hide .content {
      margin-top: 0;
      padding-top: 0;
      transition: all ease-out 0.3s;
    }
  
    .content-section.hide .note-card-holder {
      margin-top: 0.5rem;
      transition: all ease-out 0.3s;
    }
  
    .note-card-holder .note-card, .content-section.hide .note-card-holder .note-card {
      margin-left: 1%;
      margin-right: 1%;
      max-width: 98%;
      min-width: 98%;
      transition: all ease-out 0.3s;
    }
  }
  
  /* iPhoneX, iPhone 6,7,8 */
  @media only screen and (max-width: 375px) {
  }
  
  /* Galaxy S5, Moto G4 */
  @media only screen and (max-width: 360px) {
  }
  
  /* iPhone 5 or before */
  @media only screen and (max-width: 320px) {
  }  
}
