/* ========= Colors ============
Heading:     #1C2D41
Paragraph:   #3C4858
Iconography: #8392A5
Secondary:   #C0CCDA
Dirty Snow:  #E5E9F2
Snow:        #F9FAFC

Magenta: #7551E9
Orange:  #FF7D51
Pink:    #ED63D2
Green:   #2DCA73
Yellow:  #FFC212
*/

/* 
  Removed smooth scrolling implementation in main.js in favor of
  simpler css approach.
  See: https://css-tricks.com/snippets/jquery/smooth-scrolling/ 
*/
*, html {
    scroll-behavior: smooth !important;
  }
  
  /*
  Fixes anchor overlapping with header.
  See: https://stackoverflow.com/questions/4086107/fixed-page-header-overlaps-in-page-anchors 
  */
  :target::before {
    content: "";
    display: block;
    height: 2em; /* fixed header height*/
    margin: -2em 0 0; /* negative fixed header height */
  }
  
  body {
    background-color: #f9fafc;
    font-family: "Mulish";
  }
  
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: #1c2d41;
  }
  
  strong {
    color: #1c2d41 !important;
  }
  
  p {
    color: #3c4858;
  }
  
  a {
    color: #248aaa;
  }
  
  a:hover {
    color: #207089;
  }
  
  .nav-button {
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: #8392a5;
  }
  
  .btn-dark {
    background-color: #3c4858!important;
    border-color: #3c4858!important;
    color: #e5e9f2!important;
    transition: all 0.3s ease-out!important;
  }
  
  .btn-dark:hover,
  .btn-dark:focus {
    background-color: #248aaa!important;
    border-color: #248aaa!important;
    transition: all 0.3s ease-out!important;
  }
  
  .btn-outline-info {
    color: #2098d1 !important;
    border-color: #2098d1 !important;
  }
  
  .btn-outline-info:hover {
    background-color: #2098d1 !important;
    color: #e5e9f2 !important;
  }
  
  .btn-info {
    background-color: #248aaa !important;
    color: #e5e9f2 !important;
  }
  
  .btn-info:hover {
    background-color: #2098d1 !important;
    color: #e5e9f2 !important;
  }
  
  .btn-link {
    color: #248aaa;
  }
  
  .btn-link:hover {
    color: #207089;
  }
  
  .bg-white {
    background-color: #f9fafc !important;
  }
  
  .bg-dimmed {
    background-color: #e5e9f2;
  }
  
  .anchor {
    padding-top: 3.5rem;
  }
  
  img.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  img.left {
    display: block;
    margin-right: auto;
  }
  
  img.right {
    display: block;
    margin-left: auto;
  }
  
  .card {
    box-shadow: none;
    transition: all 0.3s ease-out;
    overflow: hidden;
  }
  
  .card-img-sm {
    width: 32px;
    height: 32px;
  }
  
  .card-img-xs {
    width: 24px;
    height: 24px;
  }
  
  .card:hover,
  .card:focus {
    box-shadow: 0px 8px 56px rgba(15, 80, 100, 0.16);
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }
  
  .card .card-head {
    height: 172px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .card-img-top {
    transition: all 0.3s ease-out !important;
  }
  
  .card:hover .card-head .card-img-top,
  .card:focus .card-head .card-img-top {
    transition: all 0.3s ease-out;
    transform: scale(1.2);
  }
  
  .card-body {
    text-align: justify;
  }
  
  .sub-title {
    color: #c0ccda;
    font-size: 10pt;
  }
  
  .flag-icon {
    width: 16px !important;
    margin-top: 3px;
    margin-right: 3px;
  }
  
  /* ====== table ====== */
  table {
    border-radius: 0.1rem;
    background: #e5e9f2;
    border: 1px solid #c0ccda;
    padding: 0.1rem;
  }
  
  table tr {
    height: 40px !important;
  }
  
  table th,
  td {
    padding: 0.5rem;
    border-left: 1px solid #8392a5;
    border-bottom: 1px solid #8392a5;
  }
  
  table thead tr {
    background: #248aaa;
    color: #e5e9f2;
  }
  
  tbody tr:nth-child(odd) {
    background-color: #e5e9f2;
  }
  
  tbody tr:hover {
    background: #c0ccda;
  }
  
  /* ====== don't apply css to tables inside gist ====== */
  .gist table {
    border-radius: unset;
    background: unset;
    border: unset;
    padding: unset;
  }
  
  .gist table tr {
    height: unset !important;
  }
  
  .gist table th,
  td {
    padding: unset;
    border-left: unset;
    border-bottom: unset;
  }
  
  .gist table thead tr {
    background: unset;
    color: unset;
  }
  
  .gist tbody tr:nth-child(odd) {
    background-color: unset;
  }
  
  .gist tbody tr:hover {
    background: unset;
  }
  
  .gist table td, .gist table tc{
    border-right: 1px solid #eee;
  }
  
  figure {
    border: 1px solid #c0ccda;
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-self: center;
    margin: auto;
  }
  
  img {
    max-width: 100%;
  }
  
  caption,
  figcaption {
    caption-side: bottom;
    text-align: center;
    color: #8392a5;
  }
  
  pre {
    margin: 5px;
  }
  
  pre > code {
    padding: 10px !important;
  }
  
  a.header-anchor {
    text-decoration: none;
    color: #1c2d41;
  }
  
  a.header-anchor i, a.header-anchor svg {
    font-size: 10pt;
    color: #3c4858;
    display: none;
    margin-left: 0.5rem;
  }
  a.header-anchor:hover i, a.header-anchor:hover svg {
    display: inline-block;
  }
  a.header-anchor code {
    color: #e83e8c;
  }
  
  .content ul > ol,
  .content ol > ul,
  .content ul > ul,
  .content ol > ol,
  .content li > ol,
  .content li > ul {
    -webkit-padding-start: 1rem;
    padding-inline-start: 1rem;
  }
  
  kbd {
    background-color: #248aaa !important;
    color: #f9fafc;
  }
  
  mark {
    background-color: #ffc21280;
  }
  
  /* ======= Paginator ========= */
  .paginator {
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    vertical-align: bottom;
  }
  
  .paginator .page-item > a {
    color: #248aaa;
  }
  
  .paginator .page-item.active > a {
    background-color: #248aaa;
    color: #f9fafc;
  }
  
  /* --- FOOTER START --- */
  
  .footer {
    color: #8392a5 !important;
    background-color: #1c2d41;
    position: relative;
    z-index: 9999;
  }
  
  .footer h5 {
    color: #c0ccda;
  }
  .footer a {
    color: #8392a5;
    transition: all 0.3s ease-out;
  }
  
  .footer a:hover {
    margin-left: 5px;
    transition: all 0.3s ease-out;
  }
  
  .footer ul {
    list-style: none;
    padding-left: 0;
  }
  
  .footer li {
    margin-top: 5px;
  }
  
  .footer hr {
    background-color: #8392a5;
  }
  
  .footer p:first-child {
    color: #c0ccda;
  }
  
  .footer input {
    background-color: #c0ccda;
  }
  
  .footer input:focus {
    background-color: #e5e9f2;
  }
  
  .footer #disclaimer{
    color: #8392a5 !important;
    text-align: justify;
  }
  .footer #disclaimer>strong{
    color: #c0ccda!important;
  }
  
  .footer #theme {
    color: #c0ccda;
  }
  
  .footer #theme img {
    width: 32px;
  }
  
  .footer #hugo:hover {
    margin-right: 5px;
    transition: all 0.3s ease-out;
  }
  
  /* --- FOOTER END ---- */
  
  /* ============= Device specific fixes ======= */
  
  /* Large screens such as TV */
  @media only screen and (min-width: 1824px) {
  }
  
  /* Extra large devices (large desktops, 1200px and up) */
  
  @media (max-width: 1400px) {
  }
  
  @media (max-width: 1200px) {
  }
  
  /* IPad Pro */
  @media (max-width: 1024px) {
    .content-section .languageSelector {
      position: fixed;
      right: 0.5rem;
      bottom: 1rem;
      z-index: 10000000;
      background-color: #f9fafc;
      box-shadow: 0px 8px 56px rgba(15, 80, 100, 0.16);
    }
    pre {
      margin: 0px;
    }
    code {
      padding: 0px;
    }
  }
  
  /*  Large devices (desktops, 992px and up) */
  
  @media (max-width: 992px) {
  }
  
  /* Medium devices (tablets, 768px and up) */
  
  @media only screen and (max-width: 768px) {
  }
  
  /* Small devices (landscape phones, 576px and up) */
  
  @media only screen and (max-width: 576px) {
    .skills-section .container,
    .projects-section .container,
    .publications-section .container {
      padding-left: 0.3rem;
      padding-right: 0.3rem;
    }
  
    .section-holder {
      padding-left: 5px;
      padding-right: 5px;
    }
  
    .skills-section,
    .projects-section,
    .recent-posts-section,
    .achievements-section,
    .publications-section {
      padding-left: 0;
      padding-right: 0;
    }
  
    pre {
      margin: 0px;
    }
    code {
      padding: 0px;
    }
  
    h1 {
      font-size: 2.2rem;
    }
  }
  
  /* iPhoneX, iPhone 6,7,8 */
  @media only screen and (max-width: 375px) {
    h1 {
      font-size: 2rem;
    }
  }
  
  /* Galaxy S5, Moto G4 */
  @media only screen and (max-width: 360px) {
    h1 {
      font-size: 1.8rem;
    }
  }
  
  /* iPhone 5 or before */
  @media only screen and (max-width: 320px) {
    h1 {
      font-size: 1.5rem;
    }
  }
  
