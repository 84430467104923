// in Hugo, Page kind can be either "section" or "page".
// if it is section, then it's a page with a list of items, for example /posts
// if it is page, then it is a single page.
body.kind-section {
  .wrapper {
    display: flex;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  
  .content-section {
    flex: 80%;
    order: 2;
    /* background-color: lightseagreen; */
    padding: 0;
    position: relative;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  
  .content {
    padding: 0;
    position: relative;
    padding-top: 2rem;
    min-height: 130vh;
  }
  
  .post-card-holder {
    margin-top: 32px;
    margin-left: auto;
    padding: 0;
    display: flex;
    flex-flow: wrap;
  }
  
  .post-card-holder .post-card {
    width: calc(100% / 3);
    display: inline-flex;
  }
  
  .post-card-holder .card {
    margin: 5px;
    position: relative;
  }
  
  .post-card-holder .card .card-footer span {
    font-size: 10pt;
    color: #6c757d !important;
    padding-top: 5px;
  }
  
  .post-card-holder .card .card-footer {
    background: #fff;
    margin-top: auto;
  }
  
  .post-summary {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-height: 144px;
    /* fallback */
    -webkit-line-clamp: 5;
    /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  
  .post-card-holder .post-card-link {
    text-decoration: none;
  }
  
  .paginator {
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
  }
  
  .paginator .page-item > a {
    color: #248aaa;
  }
  
  .paginator .page-item.active > a {
    background-color: #248aaa;
    color: #f9fafc;
  }
  
  .navbar-toggler {
    display: none;
  }
  
  .pagination {
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: -moz-fit-content;
    width: fit-content;
  }
  
  /* ============= Device specific fixes ======= */
  
  /* Large screens such as TV */
  @media only screen and (min-width: 1824px) {
    .content-section {
      padding-left: 1rem;
      padding-right: 1rem;
      flex: 85%;
      max-width: 85%;
    }
    .post-card-holder .post-card {
      width: calc(100% / 5);
    }
  }
  
  /* Extra large devices (large desktops, 1200px and up) */
  
  @media (max-width: 1400px) {
    .post-card-holder {
      margin-left: 0px;
    }
    .post-card-holder .post-card {
      width: calc(100% / 3);
    }
  }
  
  @media (max-width: 1200px) {
  }
  
  /* IPad Pro */
  @media (max-width: 1024px) {
    .wrapper {
      padding-left: 0px;
      padding-right: 0px;
    }
    .content-section {
      padding: 0;
      flex: 60%;
      order: 2;
      padding-bottom: 0.5rem;
    }
  
    .content {
      overflow: hidden;
    }
    .container {
      max-width: 100%;
    }
    .navbar-toggler {
      display: block;
    }
  
    #toc-toggler {
      visibility: hidden;
    }
  
    .navbar-collapse.lang-selector {
      display: block !important;
      position: absolute;
      right: 0;
      top: 0.5rem;
    }
  
    .post-card-holder {
      margin: 0;
      margin-top: 1.5rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      position: relative;
      transition: all ease-out 0.3s;
    }
  
    .post-card-holder .post-card {
      width: calc(100% / 3);
    }
  
    .content-section.hide .post-card-holder .post-card {
      width: 50%;
    }
  }
  
  /*  Large devices (desktops, 992px and up) */
  
  @media (max-width: 992px) {
    .post-card-holder .post-card {
      width: 50%;
    }
  
    .content-section.hide .post-card-holder .post-card {
      width: 100%;
    }
  }
  
  /* Medium devices (tablets, 768px and up) */
  
  @media only screen and (max-width: 768px) {
  }
  
  /* Small devices (landscape phones, 576px and up) */
  
  @media only screen and (max-width: 576px) {
    .wrapper {
      padding-left: 0px;
      padding-right: 0px;
      flex-flow: column;
      overflow: hidden;
    }
    .content-section {
      flex: 100%;
      max-width: 100%;
      padding-left: 0;
      width: 100%;
    }
  
    .content {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      transition: all ease-out 0.3s;
    }
  
    .content-section.hide .content {
      margin-top: 0;
      padding-top: 0;
      transition: all ease-out 0.3s;
    }
  
    .content-section.hide .post-card-holder {
      margin-top: 0.5rem;
      transition: all ease-out 0.3s;
    }
  
    .post-card-holder .post-card {
      margin-left: 1%;
      margin-right: 1%;
      width: 98%;
    }
  }
  
  /* iPhoneX, iPhone 6,7,8 */
  @media only screen and (max-width: 375px) {
  }
  
  /* Galaxy S5, Moto G4 */
  @media only screen and (max-width: 360px) {
  }
  
  /* iPhone 5 or before */
  @media only screen and (max-width: 320px) {
  }  
}
