.home {
  height: 100vh;
  padding: 0;
  margin: 0;
  color: #f9fafc;
  overflow: hidden;

  .background {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    background-attachment: fixed;
    background-position: center;
    transform: scale(1.1);
    filter: none;
    background-size: cover;
  }
  
  .arrow-center {
    display: flex;
    justify-content: center;
  }
  
  /*
    Resolves https://github.com/hugo-toha/toha/issues/70
  
    fixed attached images use the whole <body> size. On mobile this can get really
    tall which blows your image out. Setting the attachment back to scroll allows
    your cover image to stretch within its own container
  */
  @supports (-webkit-touch-callout: none) {
    .background {
      background-attachment: scroll;
    }
  }
  
  .content {
    position: relative;
    top: -65%;
    height: 60%;
  }
  
  img {
    width: 200px;
    height: 200px;
    background-color: #e7e7ef;
    padding: 5px;
    margin-bottom: 10px;
  }
  
  .greeting, .greeting-subtitle {
    color: #f9fafc;
  }
  
  .typing-carousel {
    font-size: 20pt;
    color: #f0f0f0;
  }
  
  #typing-carousel-data {
    display: none;
  }
  
  .arrow {
    position: absolute;
    color: #f9fafc;
    font-size: 1.5rem;
    bottom: 0;
  }
  
  .bounce {
    animation: bounce 2s infinite;
  }
  
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  
  /* ============= Device specific fixes ======= */
  
  /* Large screens such as TV */
  @media only screen and (min-width: 1824px) {
  }
  
  /* Extra large devices (large desktops, 1200px and up) */
  
  @media (max-width: 1400px) {
  }
  
  @media (max-width: 1200px) {
  }
  
  /* IPad Pro */
  @media (max-width: 1024px) {
  }
  
  /*  Large devices (desktops, 992px and up) */
  
  @media (max-width: 992px) {
  }
  
  /* Medium devices (tablets, 768px and up) */
  
  @media only screen and (max-width: 768px) {
  }
  
  /* Small devices (landscape phones, 576px and up) */
  
  @media only screen and (max-width: 576px) {
    .content {
      position: relative;
      top: -75%;
      height: 65%;
    }
  
    img {
      width: 140px;
      max-width: 50%;
      height: auto;
    }
  
    .greeting {
      font-size: 24pt;
    }
  }
  
  /* iPhoneX, iPhone 6,7,8 */
  @media only screen and (max-width: 375px) {
  }
  
  /* Galaxy S5, Moto G4 */
  @media only screen and (max-width: 360px) {
  }
  
  /* iPhone 5 or before */
  @media only screen and (max-width: 320px) {
  }  
}