body.kind-404 {
  .navbar-toggler {
    display: none;
  }
  
  .notFound {
    padding-top: 5rem;
    text-align: center;
    padding-bottom: 8rem;
  }
  
  .notFound img {
    height: 500px;
  }
  
  .notFound h1 {
    font-style: italic;
  }
  
  .notFound .message {
    position: absolute;
    max-width: 20rem;
    top: 40%;
    left: 30%;
  }
  
  /* ============= Device specific fixes ======= */
  
  /* Large screens such as TV */
  @media only screen and (min-width: 1824px) {
  }
  
  /* Extra large devices (large desktops, 1200px and up) */
  
  @media (max-width: 1400px) {
  }
  
  @media (max-width: 1200px) {
    .notFound .message {
      top: 50%;
      left: 25%;
    }
  }
  
  /* IPad Pro */
  @media (max-width: 1024px) {
  }
  
  /*  Large devices (desktops, 992px and up) */
  
  @media (max-width: 992px) {
    .notFound .message {
      top: 46%;
      left: 25%;
    }
  }
  
  /* Medium devices (tablets, 768px and up) */
  
  @media only screen and (max-width: 768px) {
    .notFound img {
      height: 400px;
    }
  
    .notFound .message {
      top: 20rem;
      left: 4rem;
    }
  }
  
  /* Small devices (landscape phones, 576px and up) */
  
  @media only screen and (max-width: 576px) {
    .notFound img {
      height: 250px;
    }
  
    .notFound .message {
      top: 20rem;
      left: 2rem;
    }
  }
  
  /* iPhoneX, iPhone 6,7,8 */
  @media only screen and (max-width: 375px) {
  }
  
  /* Galaxy S5, Moto G4 */
  @media only screen and (max-width: 360px) {
  }
  
  /* iPhone 5 or before */
  @media only screen and (max-width: 320px) {
  }  
}
