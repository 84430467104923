.publications-section {
  h1 > span{
    margin-top: -55px;  /* Size of fixed header */
    padding-bottom:55px; 
    display: block; 
  }
  
  .card {
    background: #fff;
    border-top: 2px solid #248aaa;
  }
  .card .card-header {
    background: none;
    border: none;
    display: flex;
    flex-direction: column;
  }
  
  .card .card-header .sub-title {
    color: #8392a5;
    margin-top: 0.4rem;
  }
  
  .card .sub-title :nth-child(2) {
    float: right !important;
  }
  
  .card .card-body {
    padding: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .card .card-footer {
    background: #fff;
    border: none;
    padding: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0.3rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .card .card-footer .tags {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .card .card-footer .tags .badge {
    cursor: auto;
  }
  
  .filtr-publications {
    padding: 1rem !important;
  }
  
  .btn-group{
    justify-content: center;
  }
  
  /* ============= Device specific fixes ======= */
  
  /* Large screens such as TV */
  @media only screen and (min-width: 1824px) {
  }
  
  /* Extra large devices (large desktops, 1200px and up) */
  
  @media (max-width: 1400px) {
  }
  
  @media (max-width: 1200px) {
  }
  
  /* IPad Pro */
  @media (max-width: 1024px) {
    padding-left: 0;
    padding-right: 0;
    width: 100%;

    .container {
      max-width: 100%;
    }
    
    .filtr-publications {
      padding: 0;
    }
    
    .pub-filtr-item {
      padding-left: 0.2rem;
      padding-right: 0.2rem;
    }
  }
  
  /*  Large devices (desktops, 992px and up) */
  
  @media (max-width: 992px) {
  }
  
  /* Medium devices (tablets, 768px and up) */
  
  @media only screen and (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
    width: 100%;

    .container {
      max-width: 100%;
    }
    .filtr-publications {
      padding: 0;
    }
    .pub-filtr-item {
      padding-left: 0.2rem;
      padding-right: 0.2rem;
      flex: 100%;
    }
  }
  
  /* Small devices (landscape phones, 576px and up) */
  
  @media only screen and (max-width: 576px) {
    .btn {
      margin-top: 0.3125rem;
    }
    .pub-filtr-item {
      flex: 100%;
      max-width: 100%;
    }
    .card .card-footer .tags {
      flex: 100%;
    }
  }
  
  /* iPhoneX, iPhone 6,7,8 */
  @media only screen and (max-width: 375px) {
  }
  
  /* Galaxy S5, Moto G4 */
  @media only screen and (max-width: 360px) {
  }
  
  /* iPhone 5 or before */
  @media only screen and (max-width: 320px) {
  }  
}