.recent-posts-section {
  .container {
    padding-top: 1rem;
  }
  
  h1 > span{
    margin-top: -55px;  /* Size of fixed header */
    padding-bottom:55px; 
    display: block; 
  }
  
  .card {
    height: 100%;
    min-height: 100%;
  }
  
  .card .card-footer span {
    font-size: 10pt;
    color: #6c757d !important;
    padding-top: 5px;
  }
  
  .card .card-footer {
    background: #fff;
    margin-top: auto;
  }
  
  .post-card-link {
    text-decoration: none;
  }
  
  .post-summary {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    /* line-height: 24px;     fallback */
    max-height: 144px; /* fallback */
    -webkit-line-clamp: 5; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  
  /* ============= Device specific fixes ======= */
  
  /* Large screens such as TV */
  @media only screen and (min-width: 1824px) {
  }
  
  /* Extra large devices (large desktops, 1200px and up) */
  
  @media (max-width: 1400px) {
  }
  
  @media (max-width: 1200px) {
  }
  
  /* IPad Pro */
  @media (max-width: 1024px) {
  }
  
  /*  Large devices (desktops, 992px and up) */
  
  @media (max-width: 992px) {
  }
  
  /* Medium devices (tablets, 768px and up) */
  
  @media only screen and (max-width: 768px) {
    .container {
      max-width: 100%;
    }
    .post-card {
      width: 50%;
    }
  }
  
  /* Small devices (landscape phones, 576px and up) */
  
  @media only screen and (max-width: 576px) {
    .post-card {
      width: 100%;
    }
  }
  
  /* iPhoneX, iPhone 6,7,8 */
  @media only screen and (max-width: 375px) {
  }
  
  /* Galaxy S5, Moto G4 */
  @media only screen and (max-width: 360px) {
  }
  
  /* iPhone 5 or before */
  @media only screen and (max-width: 320px) {
  }  
}