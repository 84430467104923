/* Note: No need to invert when the screen is small because the navbar is
   collapsed to a hamburger menu. */
   @media only screen and (min-width: 1200px) {
    .dynamic-navbar .navbar-icon-svg-dark {
      filter: invert(1);
    };
  }
  
  @media only screen and (min-width: 1200px) {
    .dropdown-menu-icons-only {
      width: 25px;
      min-width: 3rem;
    }
  }
  
  .menu-icon-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }