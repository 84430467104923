.education-section {
  .education-info-table {
    width: 100%;
    border: none;
    background: none;
  }
  
  .education-info-table tr:hover {
    background: none;
  }
  
  .education-info-table tr,
  .education-info-table th,
  .education-info-table td {
    border: none;
    padding: 0;
    background: none;
  }
  
  .timeframe {
    color: #8392a5;
    text-align: right;
  }
  
  h1 > span{
    margin-top: -55px;  /* Size of fixed header */
    padding-bottom:55px;
    display: block;
  }
  
  .icon {
    width: 2rem;
    padding-left: 0;
    padding-right: 0;
    position: relative;
  }
  
  .icon .hline {
    position: absolute;
    left: 1rem;
    top: 0;
    background-color: #248aaa;
    height: 100%;
    width: 2px;
  }
  
  .education-info-table tr:first-child .hline {
    height: 65%;
    top: auto;
  }
  
  .education-info-table tr:last-child .hline {
    height: 50%;
  }
  
  .icon-holder {
    background-color: #248aaa;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    padding: 0.2rem;
    text-align: center;
    color: #e5e9f2;
    position: relative;
  }
  
  .line {
    width: 5%;
    padding-left: 0;
    padding-right: 0;
  }
  
  .line div {
    height: 2px;
    /* width: 100%; */
    margin-right: -1px;
    background-color: #248aaa;
  }
  
  .degree-info {
    padding: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-left: 2px solid #248aaa;
    border-top: 1px solid #c0ccda;
    border-bottom: 1px solid #c0ccda;
    border-right: 1px solid #c0ccda;
    border-radius: 5px;
  }
  
  .degree-info h5 {
    margin-bottom: 0.3rem;
  }
  
  .taken-courses table {
    margin-left: 1rem;
    width: 100%;
    transition: all 0.3s ease-out;
    background: none;
    border: none;
  }
  
  .taken-courses tr {
    height: auto !important;
  }
  
  .taken-courses tr,
  .taken-courses td,
  .taken-courses th {
    background: none;
    border: none;
    color: #212529;
  }
  .taken-courses th.course-name-header{
    width: 50%;
  }
  .taken-courses .hidden-course {
    display: none;
    transition: all 1s ease-out;
  }
  
  .taken-courses ul {
    margin-bottom: 0;
  }
  
  /*============ Education Alter Template =============*/
  .education-alt .degree-info {
    border-right: 2px solid #248aaa;
  }
  
  /* ============= Device specific fixes ======= */
  
  /* Large screens such as TV */
  
  @media only screen and (min-width: 1824px) {
  }
  
  /* Extra large devices (large desktops, 1200px and up) */
  
  @media (max-width: 1400px) {
  }
  
  @media (max-width: 1200px) {
  }
  
  /* IPad Pro */
  
  @media (max-width: 1024px) {
    .container {
      padding-left: 0;
    }
  }
  
  /*  Large devices (desktops, 992px and up) */
  
  @media (max-width: 992px) {
  }
  
  /* Medium devices (tablets, 768px and up) */
  
  @media only screen and (max-width: 768px) {
  }
  
  /* Small devices (landscape phones, 576px and up) */
  
  @media only screen and (max-width: 576px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    .container {
      padding-right: 0;
    }
  
    .icon {
      display: none;
    }
    .line {
      display: none;
    }
    .timeframe {
      text-align: left;
    }
  }
  
  /* iPhoneX, iPhone 6,7,8 */
  
  @media only screen and (max-width: 375px) {
  }
  
  /* Galaxy S5, Moto G4 */
  
  @media only screen and (max-width: 360px) {
  }
  
  /* iPhone 5 or before */
  
  @media only screen and (max-width: 320px) {
  }  
}