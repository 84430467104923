body.kind-page {
  background-color: #e5e9f2;
  position: relative;

  .read-area {
    background-color: #f9fafc;
  }
  
  .wrapper {
    display: flex;
    padding: 0;
    margin: 0;
    width: 100%;
    justify-content: space-between;
    position: relative;
  }
  
  .content-section {
    flex: 60%;
    max-width: 60%;
    order: 2;
    /* background-color: lightseagreen; */
    padding: 0;
    position: relative;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .content {
    background: #e5e9f2;
  }
  .toc-section {
    flex: 20%;
    order: 3;
    max-width: 20%;
    /* background-color: lightpink; */
    transition: all ease-out 0.5s;
  }
  
  .toc-holder {
    position: sticky;
    top: 4.5rem;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #f9fafc;
    margin-right: 0.5rem;
    /* box-shadow: 0px 8px 56px rgba(15, 80, 100, 0.16); */
    transition: all ease-out 0.3s;
  }
  
  .toc {
    position: relative;
    padding-top: 0px;
    transition: all ease-out 0.3s;
  }
  
  .toc nav {
    padding-top: 0px;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: all ease-out 0.3s;
  }
  
  .toc ul {
    list-style: none;
    padding-left: 0.5rem;
    margin-bottom: 0rem;
    width: 100%;
  }
  
  .toc .nav-link {
    padding: 0;
    padding-left: 0.5rem;
    transition: all ease-out 0.3s;
    color: #1c2d41;
  }
  
  .toc .nav-link:hover,
  .toc .nav-link:focus,
  .toc .nav-link.active {
    padding-left: 1rem;
    padding-right: 0.5rem;
    background-color: #248aaa;
    color: #f9f9f9;
    transition: all ease-out 0.3s;
  }
  
  .hero-area {
    margin-top: 3rem;
    width: 100%;
    height: 400px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .page-content {
    width: 100%;
    position: relative;
    top: -4.5rem;
    padding: 15px;
  }
  
  .author-profile {
    position: relative;
    align-content: center;
    text-align: center;
  }
  
  .author-name {
    margin-top: 0px;
  }
  
  .author-profile img {
    height: 120px;
    width: 120px;
    -o-object-fit: cover;
    object-fit: cover;
    background-color: #f9fafc;
    padding: 5px;
  }
  .author-profile p {
    color: #8392a5;
  }
  
  .title {
    text-align: center;
  }
  
  .post-content {
    padding: 15px;
  }
  
  .post-content h1,
  h2 {
    margin-top: 1.4rem;
  }
  
  .post-content h3,
  h4,
  h5,
  h6 {
    margin-top: 1.3rem;
  }
  
  .post-content blockquote {
    border-left: 4px solid #248aaa;
    background-color: #248baa15;
    padding: 0.3rem;
    padding-left: 1rem;
  }
  
  .post-content blockquote > p {
    color: #3c4858;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  .next-prev-navigator {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .next-prev-navigator a {
    color: #2098d1;
    transition: all 0.3s ease-out;
  }
  
  .next-prev-navigator a:hover {
    color: #3c4858;
    transition: all 0.3 ease-out;
  }
  
  .next-prev-navigator .next-article {
    text-align: right;
  }
  
  .next-prev-navigator .next-article a {
    transition: all 0.3s ease-out;
  }
  
  .next-prev-navigator .previous-article a {
    transition: all 0.3s ease-out;
  }
  
  .next-prev-navigator .btn-outline-info {
    color: #f9fafc !important;
    border-color: #e5e9f2 !important;
    background-color: #248aaa !important;
    transition: all 0.3s ease-out;
  }
  
  .next-prev-navigator .next-prev-text {
    white-space: break-spaces;
  }
  
  .next-prev-navigator .btn-outline-info:hover {
    color: #3c4858 !important;
    background-color: #e5e9f2 !important;
    transition: all 0.3s ease-out;
  }
  
  .disquss {
    padding: 10px;
  }
  
  .share-buttons .btn {
    color: #e5e9f2 !important;
    transition: all 0.3s ease-out !important;
  }
  
  .share-buttons .btn:hover,
  .share-buttons .btn:focus {
    background-color: #248aaa !important;
    border-color: #248aaa !important;
    transition: all 0.3s ease-out !important;
  }
  
  .share-buttons .facebook-btn {
    background-color: #4267b2 !important;
    border-color: #4267b2 !important;
  }
  
  .share-buttons .twitter-btn {
    background-color: #1da1f2 !important;
    border-color: #1da1f2 !important;
  }
  
  .share-buttons .reddit-btn {
    background-color: #ff4500 !important;
    border-color: #ff4500 !important;
  }
  
  .share-buttons .tumblr-btn {
    background-color: #34465d !important;
    border-color: #34465d !important;
  }
  
  .share-buttons .pocket-btn {
    background-color: #ef4056 !important;
    border-color: #ef4056 !important;
  }
  
  .share-buttons .linkedin-btn {
    background-color: #2867b2 !important;
    border-color: #2867b2 !important;
  }
  
  .share-buttons .diaspora-btn {
    background-color: #3c4858 !important;
    border-color: #3c4858 !important;
  }
  
  .share-buttons .mastodon-btn {
    background-color: #2791da !important;
    border-color: #2791da !important;
  }
  
  .share-buttons .whatsapp-btn {
    background-color: #4ac959 !important;
    border-color: #4ac959 !important;
  }
  
  .share-buttons .email-btn {
    background-color: #3c4858 !important;
    border-color: #3c4858 !important;
    transition: all 0.3s ease-out !important;
  }
  
  .btn-improve-page {
    text-align: right;
  }
  
  .languageSelector {
    display: none;
  }
  
  #scroll-to-top {
    position: fixed;
    bottom: 0rem;
    right: 1rem;
    color: #248aaa;
    font-size: 24pt;
    z-index: 900000;
    visibility: hidden;
  }
  
  #scroll-to-top i {
    box-shadow: 0px 8px 56px rgba(15, 80, 100, 0.16);
    background-color: #f9f9f9;
    border-radius: 50%;
  }
  
  #scroll-to-top:hover {
    color: #2098d1;
  }
  
  #scroll-to-top.show {
    visibility: visible;
  }
  .taxonomy-terms {
    text-align: center;
  }
  .taxonomy-terms li {
    font-size: 0.8em;
    list-style-type: none;
    display: inline-block;
    background: #248aaa;
    margin-left: 0.2em;
    margin-right: 0.2em;
  }
  
  .taxonomy-terms a {
    color: #f9fafc;
  }
  
  /* ============= Device specific fixes ======= */
  
  /* Large screens such as TV */
  @media only screen and (min-width: 1824px) {
    .content-section {
      flex: 65%;
      max-width: 65%;
    }
    .content-section .container {
      max-width: 100%;
    }
  }
  
  /* Extra large devices (large desktops, 1200px and up) */
  
  @media (max-width: 1400px) {
  }
  
  @media (max-width: 1200px) {
  }
  
  /* IPad Pro */
  @media (max-width: 1024px) {
    .wrapper {
      padding-left: 0px;
      padding-right: 0px;
    }
  
    .content-section {
      padding: 0;
      flex: 60%;
      max-width: 100%;
      order: 2;
      overflow: hidden;
    }
    .content {
      overflow: hidden;
    }
    .container {
      max-width: 100%;
    }
  
    .toc-section {
      order: 3;
      flex: 0%;
      max-width: 0%;
      transition: all ease-out 0.3s;
    }
    .toc-section.hide {
      flex: 40%;
      max-width: 40%;
      margin-left: 0.5rem;
      transition: all ease-out 0.3s;
    }
  
    .toc-holder {
      top: 3rem;
      max-height: calc(100vh - 3rem);
    }
  
    .navbar-toggler {
      display: block;
    }
  
    .navbar-collapse.lang-selector {
      display: none;
    }
  
    .languageSelector {
      display: block;
    }
  
    .hero-area {
      height: 300px;
      margin-top: 1rem;
    }
  
    .page-content {
      padding: 0px;
    }
  
    .btn-improve-page {
      margin-right: 1rem;
    }
  
    #disqus_thread,
    .dsq-brlink {
      padding: 5px;
    }
  }
  
  /*  Large devices (desktops, 992px and up) */
  
  @media (max-width: 992px) {
  }
  
  /* Medium devices (tablets, 768px and up) */
  
  @media only screen and (max-width: 768px) {
    #scroll-to-top {
      right: 8rem;
    }
  }
  
  /* Small devices (landscape phones, 576px and up) */
  
  @media only screen and (max-width: 576px) {
    .wrapper {
      padding: 0px;
      display: flex;
      flex-direction: column;
    }
  
    .content-section {
      padding: 0;
      flex: 100%;
      max-width: 100%;
      order: 3;
    }
  
    .toc-section {
      order: 2;
      width: 100%;
      height: -moz-fit-content;
      height: fit-content;
      max-height: 0;
      max-width: 100%;
      transition: all ease-out 0.5s;
    }
  
    .toc-section.hide {
      margin-top: 2.5rem;
      position: relative;
      /* height: fit-content; */
      flex: 100%;
      height: -moz-fit-content;
      height: fit-content;
      max-height: 200vh;
      max-width: 100%;
      margin-left: 0;
      padding-right: 0;
      box-shadow: 0px 8px 56px rgba(15, 80, 100, 0.16);
      transition: all ease-out 0.5s;
    }
  
    .toc-holder {
      max-height: 0;
      margin-right: 0;
      overflow: hidden;
      transition: all ease-out 0.5s;
    }
    .toc-section.hide .toc-holder {
      max-height: 200vh;
      transition: all ease-out 0.5s;
    }
  
    .navbar-toggler {
      display: block;
    }
  
    .hero-area {
      height: 200px;
      margin-top: 1rem;
    }
  
    .page-content {
      padding: 0px;
    }
  
    .next-prev-navigator .previous-article {
      text-align: center;
      margin: 5px;
    }
    .next-prev-navigator .next-article {
      text-align: center;
      margin: 5px;
    }
    .previous-article a,
    .next-article a {
      width: 100%;
    }
  
    #disqus_thread,
    .dsq-brlink {
      padding: 5px;
    }
  }
  
  /* iPhoneX, iPhone 6,7,8 */
  @media only screen and (max-width: 375px) {
  }
  
  /* Galaxy S5, Moto G4 */
  @media only screen and (max-width: 360px) {
  }
  
  /* iPhone 5 or before */
  @media only screen and (max-width: 320px) {
  }  
}