.skills-section {
  .card .card-head {
    background-color: #f9fafc;
    height: -moz-fit-content;
    height: fit-content;
    padding: 0.7rem;
    padding-bottom: 0rem;
    border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.125);
  }
  
  h1 > span{
    margin-top: -55px;  /* Size of fixed header */
    padding-bottom:55px; 
    display: block; 
  }
  
  .skill-card-link {
    text-decoration: none;
  }
  
  .card .card-img-xs {
    margin-right: 0.5rem;
  }
  
  .card {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    height: 100%;
  }
  
  .card .card-body {
    padding-top: 0.2rem;
    padding-left: 0.7rem;
  }
  
  /* ============= Device specific fixes ======= */
  
  /* Large screens such as TV */
  @media only screen and (min-width: 1824px) {
  }
  
  /* Extra large devices (large desktops, 1200px and up) */
  
  @media (max-width: 1400px) {
  }
  
  @media (max-width: 1200px) {
  }
  
  /* IPad Pro */
  @media (max-width: 1024px) {
  }
  
  /*  Large devices (desktops, 992px and up) */
  
  @media (max-width: 992px) {
  }
  
  /* Medium devices (tablets, 768px and up) */
  
  @media only screen and (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
    
    .container {
      max-width: 95%;
    }
  }
  
  /* Small devices (landscape phones, 576px and up) */
  
  @media only screen and (max-width: 576px) {
  }
  
  /* iPhoneX, iPhone 6,7,8 */
  @media only screen and (max-width: 375px) {
  }
  
  /* Galaxy S5, Moto G4 */
  @media only screen and (max-width: 360px) {
  }
  
  /* iPhone 5 or before */
  @media only screen and (max-width: 320px) {
  }  
}